import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

export const ProductStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* position: absolute; */
`;

export const ProductDescription = styled(motion.p)`
  text-align: center;
  line-height: 28px;
  letter-spacing: 0.8px;
  margin: 0;
`;
export const ProductName = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
`;

export const ProductImage = styled.img`
  width: 250px;
`;

const Product = ({ image, description, name }) => {
  return (
    <ProductStyled>
      <ProductImage src={image} alt={name} />

      <ProductName>{name}</ProductName>
      <ProductDescription>{description}</ProductDescription>
    </ProductStyled>
  );
};

export default Product;
