import React, { useEffect, useRef } from "react";
import Page from "../components/Page";
import Hero from "../components/Hero";
import SectionTitle from "../components/SectionTitle";
import { Container } from "../components/styles/Container.style";
import { SectionStyle } from "../components/styles/SectionStyle.style";
import styled, { keyframes, useTheme } from "styled-components";
import { FaPhoneAlt } from "react-icons/fa";
import { motion, useAnimationControls, useInView } from "framer-motion";
import ProductsCarousel from "../components/products/ProductsCarousel";
import { Link } from "react-router-dom";
import AboutUs from "./home/AboutUs";
import WhyUs from "./home/WhyUs";
import dompurify from 'dompurify'
import Products from "./home/Products";
import { Helmet } from "react-helmet";
const animation = keyframes`
   0%,
    100% {
      clip-path: polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
      );
    }

    50% {
      clip-path: polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
      );
    }
`;

export const AnimatedLogo = styled.div`
  position: relative;
  /* animation: ${animation} 5s linear 10s; */
  height: 220px;

  h2 {
    color: #fff;
    font-size: 2.5em;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 30%;
    left: 50%;
    text-transform: uppercase;
    width: 100%;
    font-family: ethnocentric;
    margin: 0;
  }

  h2:nth-child(1) {
    color: transparent;
    -webkit-text-stroke: 2px ${({ theme }) => theme.colors.secondary};
  }

  h2:nth-child(2) {
    color: ${({ theme }) => theme.colors.primary};
    animation: animate 4s ease-in-out infinite;
  }

  @keyframes animate {
    0%,
    100% {
      clip-path: polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
      );
    }

    50% {
      clip-path: polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
      );
    }
  }
`;


export const AnimatedLogoWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Description = styled(motion.p)`
  // text-align: center;
  line-height: 28px;
  letter-spacing: 1.1px;
  font-size: 18px;
  color: #f8f9fa;
  flex: 2;
  /* text-shadow:  1.5px 1.5px  rgb(0 0 0 / 0.2) */

  span {
    font-weight: 600;
    font-size: 18px;
  }
`;

export const CallUsSection = styled(motion.div)`
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("/static/callus.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;

  height: 250px;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    color: white;
    > p {
      font-size: 42px;
      flex: 1 1 0px;
    }

    @media (max-width: 820px) {
      > p {
        font-size: 36px;
      }
    }
    @media (max-width: 680px) {
      > p {
        font-size: 32px;
      }
    }
    @media (max-width: 520px) {
      > p {
        font-size: 28px;
      }
    }
  }
`;

export const PhoneInfo = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 10px;
  overflow-x: hidden;
  p {
    font-size: 48px;
  }
  flex: 1 1 0px;
`;

const AboutUsBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 40px;
  gap: 20px;
  /* background-color: ${({ theme }) => theme.colors.primary}; */
  background-color: #4052b4;
`;

const Intro = styled.div`
  
  .section {
    margin: 80px 0;
    background-color: #5363bc;
    color: white;
    padding: 10px 20px;
    text-align: center;
  }

  .section h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }

  .section p {
    font-size: 20px;
    /* margin-bottom: 10px; */
    font-weight: bold;
    text-transform: capitalize;
  }

  .features {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* gap: 10px; */
    flex-wrap: wrap;
  }

  .feature {
    /* background-color: white; */
    color: white;
    /* padding: 20px; */
    border-radius: 10px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    /* width: 200px; */
    text-align: center;
  }

  .feature h2 {
    font-size: 18px;
    margin-bottom: 5px;
    font-style: italic;
  }

  .feature p {
    font-size: 16px;
  }
`;

const UnCapitalized = styled.span`
text-transform: none;
`
const Home = () => {
  const theme = useTheme();
  const ref = useRef();
  const controls = useAnimationControls();
  const isInView = useInView(ref, { once: true });
  

  useEffect(() => {
    if (isInView) {
      controls.start("open");
    }
  }, [isInView, controls]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Deep Sea Oil</title>
        <meta
          name="description"
          content="At Deep Sea Oil, we produce premium Shark Liver Oil that meets the
            stringent Australian standards AS 5380:2023 to ensure optimal
            quality and purity. Our dedication to excellence ensures that every
            drop of oil is carefully extracted and processed to retain its
            beneficial properties, making it a trusted choice for our clients."
        />
      </Helmet>
      <Hero />

      <Intro>
        <section class="section">
          {/* <h1></h1> */}
          <p style={{ lineHeight: "28px" }}>
            Australian Company owns <UnCapitalized>and</UnCapitalized> operates
            facilities {" "} 
            <UnCapitalized>in</UnCapitalized> Oman and Yemen, specializing {" "}
            <UnCapitalized>in</UnCapitalized> the production {" "}
            <UnCapitalized>of</UnCapitalized> high-quality Shark Liver Oil (SLO){" "}
            <UnCapitalized>and</UnCapitalized> seafood supplies {" "}
          </p>
          <div class="features">
            <div class="feature">
              <h2>Pure</h2>
            </div>
            <div class="feature">
              <h2>Sustainable</h2>
            </div>
            <div class="feature">
              <h2>Free of Contamination</h2>
            </div>
          </div>
        </section>
      </Intro>
      {/* </Container> */}
      <AboutUs />
      <WhyUs />
      <Products />
      <SectionStyle>
        <motion.div>
          <CallUsSection ref={ref}>
            <div
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }}
            >
              <motion.div
                style={{
                  backgroundColor: "white",

                  position: "absolute",
                  top: 0,
                  width: "100%",
                }}
                initial="initial"
                variants={{
                  initial: {
                    bottom: "50%",
                  },
                  open: {
                    bottom: "100%",
                  },
                }}
                transition={{
                  duration: 0.5,
                  delay: 0.3,
                  ease: "easeIn",
                }}
                animate={controls}
              ></motion.div>
              <motion.div
                style={{
                  backgroundColor: "white",
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                }}
                initial="initial"
                variants={{
                  initial: {
                    top: "50%",
                  },
                  open: {
                    top: "100%",
                  },
                }}
                transition={{
                  duration: 0.5,
                  delay: 0.5,
                  ease: "easeIn",
                }}
                animate={controls}
              ></motion.div>
            </div>
            <Container className="container">
              <motion.p
                whileInView={{ x: 0, opacity: 1 }}
                initial={{
                  x: -30,
                  opacity: 0,
                }}
                viewport={{ once: true }}
                transition={{
                  duration: 0.5,
                  delay: 0.6,
                  ease: "easeIn",
                }}
              >
                Discover our unique and high-quality shark liver oil and learn
                more about our products by contacting us - we promise the best
                for our clients.
              </motion.p>
            </Container>
          </CallUsSection>
        </motion.div>
      </SectionStyle>
    </>
  );
};

export default Home;
