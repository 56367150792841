import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import Products from "./pages/Products";
import  ProductDetails from "./pages/ProductDetails"
import "./static/ethnocentric rg.ttf";
import Page from "./components/Page";
import { lazy } from "react";
import NotFoundPage from "./pages/notFound/notFoundPage";
// const ProductDetails = lazy(() => import("./pages/ProductDetails")); 
// const Products = lazy(() => import("./pages/Products")); 
// const ContactUs = lazy(() => import("./pages/ContactUs")); 
// const Home = lazy(() => import("./pages/Home")); 

// import ContactUs from "./pages/ContactUs";
function App() {
  return (
    <div className="App">
      <Page>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/:productSlug" element={<ProductDetails />} />
          <Route
            path="*"
            element={
              <NotFoundPage />
            }
          />
        </Routes>
      </Page>
    </div>
  );
}

export default App;
