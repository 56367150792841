import styled from "styled-components";

export const StyledNavbar = styled.header`
  padding: 10px 0;
  position: ${({ isHome }) => (isHome ? "fixed" : "")};
  width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)";
  z-index: 20;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 40px;
  }
`;
