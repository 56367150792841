import React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";


import "../styles.css";

import { IoTriangleSharp } from "react-icons/io5";
import ProductCarouselItem from "./ProductCarouselItem";
const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
      // backgroundColor: "white",
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
    // backgroundColor: "#4052b4",
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};


const ProductsCarousel = ({products}) => {
  const [[page, direction], setPage] = useState([0, 0]);

  // We only have 3 products, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 products.
  const imageIndex = wrap(0, products.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  if (products.length ===0){
    return <></>
  }

  return (
    <div style={{ position: "relative" }}>
      <motion.div
        whileInView={{ y: 0, opacity: 1 }}
        initial={{
          y: 40,
          opacity: 0,
        }}
        viewport={{ once: true }}
        transition={{
          duration: 0.5,
          delay: 0.4,
          ease: "easeIn",
        }}
        className="example-container"
      >
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            src={products[imageIndex].image}
            transition={{
              x: { type: "just", duration: "0.4" },
              opacity: { duration: 0.3 },
            }}
            drag="x"
            style={{ position: "absolute", width: "100%",height:'100%' }}
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          >
            <ProductCarouselItem {...products[imageIndex]} />
          </motion.div>
        </AnimatePresence>
      </motion.div>
      <div className="next" onClick={() => paginate(-1)}>
        <IoTriangleSharp
          size={25}
          color="#4052b4"
          style={{ rotate: "90deg" }}
        />
      </div>
      <div className="prev" onClick={() => paginate(1)}>
        <IoTriangleSharp
          size={25}
          color="#4052b4"
          style={{ rotate: "-90deg" }}
        />
      </div>
    </div>
  );
};

export default ProductsCarousel;
