import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@160..700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Goldman:wght@400;700&family=Michroma&family=Teko:wght@300..700&family=Yatra+One&display=swap'); */
  @font-face {
  font-family: "ethnocentric";   /*Can be any text*/
  src: local("ethnocentric"),
    url("../../static/ethnocentric rg.ttf") format("truetype");
}

  * {
    box-sizing: border-box;
  }

  body {
    background: white;
    font-size: 1.15em;
    font-family: ${({ theme }) => theme.fonts.readuxPro};
    margin: 0;
  }

  p{
    margin:0;
  }


  img {
   max-width: 100%;
   
}

a{
  text-decoration: none;
}

`;
