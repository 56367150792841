import React, { useEffect, useState } from "react";
import { StyledNavbar } from "../styles/StyledNavbar.style";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Logo, NavLink, NavLinks } from "../styles/NavLinks.style";
import { Container } from "../styles/Container.style";
import styled, { useTheme } from "styled-components";
import { FaBars } from "react-icons/fa";
import SideBar from "./SideBar";
import { AnimatePresence, useCycle } from "framer-motion";

const Bar = styled.div`
  display: none;
  margin-left: auto;
  /* position: relative; */
  @media (max-width: 620px) {
    display: block;
  }
`;

const Navbar = () => {
 
  const [sidebarOpen, setSidebarOpen] = useCycle(false, true);
  const navigate = useNavigate()
  const location = useLocation();
  const isHome = location.pathname === "/";
  const theme = useTheme();

  const style = {
    color: theme.colors.primary,
    fontWeight: "600",
  };


  return (
    <StyledNavbar isHome={isHome}>
      <Container className="container">
        <Logo
          src="/static/logo.webp"
          alt="logo"
          onClick={() => navigate("/")}
        />
        <NavLinks>
          <NavLink to="/" style={location.pathname === "/" ? style : {}}>
            Home
          </NavLink>
          <NavLink
            to="/products"
            style={location.pathname === "/products" ? style : {}}
          >
            Products
          </NavLink>
          <NavLink
            to="/contact-us"
            style={location.pathname === "/contact-us" ? style : {}}
          >
            Contact Us
          </NavLink>
        </NavLinks>
        <Bar>
          <FaBars
            onClick={setSidebarOpen}
            style={{ cursor: "pointer" }}
            // style={ { zIndex: 10 }}
          />
          <SideBar open={sidebarOpen} closeSidebar={setSidebarOpen} />
        </Bar>
      </Container>
    </StyledNavbar>
  );
};

export default Navbar;
