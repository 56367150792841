import React, { useState } from "react";
import { Container } from "../../components/styles/Container.style";
import SectionTitle from "../../components/SectionTitle";
import { SectionStyle } from "../../components/styles/SectionStyle.style";
import styled from "styled-components";
import { motion } from "framer-motion";

export const AnimatedLogo = styled.div`
  position: relative;
  height: 220px;

  h2 {
    color: #fff;
    font-size: 2.5em;
    position: absolute;
    text-align: center;
    transform: translate(-50%, -50%);
    top: 30%;
    left: 50%;
    text-transform: uppercase;
    width: 100%;
    /* font-family: ethnocentric; */
    font-family: "Goldman", sans-serif;
    /* font-family: "Teko", sans-serif; */
    margin: 0;

    @media (max-width: 820px) {
      font-size: 2.2em;
    }
    @media (max-width: 520px) {
      font-size: 2em;
    }
  }

  h2:nth-child(1) {
    color: transparent;
    -webkit-text-stroke: 1px ${({ theme }) => theme.colors.secondary};
  }

  h2:nth-child(2) {
    color: ${({ theme }) => theme.colors.primary};
    animation: animate 2.5s ease-in-out infinite;
  }

  @keyframes animate {
    0%,
    100% {
      clip-path: polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
      );
    }

    50% {
      clip-path: polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
      );
    }
  }
`;

export const AnimatedLogoWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Description = styled(motion.p)`
  // text-align: center;
  line-height: 28px;
  letter-spacing: 1.1px;
  font-size: 18px;
  color: #f8f9fa;
  flex: 2;
  /* text-shadow:  1.5px 1.5px  rgb(0 0 0 / 0.2) */

  span {
    font-weight: 600;
    font-size: 18px;
  }
`;

const AboutUsBody = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 40px;
  gap: 20px;

  /* background-color: ${({ theme }) => theme.colors.primary}; */
  background-color: #5363bc;
  /* background-color: #4052b4; */

  @media (max-width: 820px) {
    flex-direction: column-reverse;
  }
`;

const ReadingButtonControl = styled.button`
  border: none;
  background-color: transparent;
  font-size: 16px;
  padding: 0;
  text-transform: uppercase;
  text-decoration: underline;
  color: white;
  cursor: pointer;
`;

const AboutUsImage = styled.img`
  /* border-radius: 10px; */
  transition: 0.5s;

  @media (max-width: 820px) {
    max-width: 80vw;
  }
`;

const AboutUs = () => {
  const [readMore, setReadMore] = useState(false);

  const toggleReadingControl = () => {
    setReadMore(!readMore);
  };

  return (
    <SectionStyle>
      <Container>
        <SectionTitle title="about us" question="who we are?" />
        <AnimatedLogoWrapper
          whileInView={{ y: 0, opacity: 1 }}
          initial={{
            y: 40,
            opacity: 0,
          }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            delay: 0.2,
            ease: "easeIn",
          }}
        >
          <div style={{ width: "500px", height: "150px" }}>
            <AnimatedLogo>
              <h2>deep sea oils</h2>
              <h2>deep sea oils</h2>
            </AnimatedLogo>
          </div>
        </AnimatedLogoWrapper>
      </Container>
      <AboutUsBody>
        <Description
          whileInView={{ x: 0, opacity: 1 }}
          initial={{
            x: -40,
            opacity: 0,
          }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            delay: 0.2,
            ease: "easeIn",
          }}
          className="text"
        >
          
          Welcome to Deep Sea Oils, a company founded by two passionate
          engineers with deep roots in the maritime traditions. With over 30
          years of family heritage in the fishing industry, we bring a wealth of
          experience and a commitment to the production of high-quality shark
          liver oil and seafood supplies.
          <br />
          <p style={{ margin: "10px 0", padding: 0 }}>
            At Deep Sea Oil, we produce premium Shark Liver Oil that meets the
            stringent Australian standards AS 5380:2023 to ensure optimal
            quality and purity. Our dedication to excellence ensures that every
            drop of oil is carefully extracted and processed to retain its
            beneficial properties, making it a trusted choice for our clients.
          </p>
          {/* <br /> */}
          {readMore ? (
            <>
              <>
                Our unique story sets us apart. As Australian-Yemeni engineers,
                we blend the best of both worlds: the rigorous scientific
                approach of modern engineering with the rich, hands-on expertise
                passed down through generations of fishermen. This combination
                allows us to deliver a product that is not only effective but
                also steeped in tradition and respect for the ocean's resources.
              </>
              <ReadingButtonControl onClick={toggleReadingControl}>
                read less
              </ReadingButtonControl>
            </>
          ) : (
            <ReadingButtonControl onClick={toggleReadingControl}>
              read more
            </ReadingButtonControl>
          )}
          {/* <span>
              {" "}
              Discover our unique and high-quality shark liver oil and learn
              more about our products by contacting us - we promise the best
              for our clients.
            </span> */}
        </Description>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            flex: 1,
          }}
        >
          <AboutUsImage src="/static/sharkoil.jpg" alt="oil" width="350px" />
        </div>
      </AboutUsBody>
    </SectionStyle>
  );
};

export default AboutUs;
