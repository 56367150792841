import { Link } from "react-router-dom";
import styled from "styled-components";

export const NavLinks = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 20px;

  @media (max-width: 620px) {
    flex-direction: column;
    display: none;
  }
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  font-size: 18px;
`;


export const Logo = styled.img`
  width: 100px;
`;
