import React, { useState } from "react";
import { PrimaryButton } from "../styles/Buttons.style";
import styled from "styled-components";

export const ContactForm = styled.div`
  flex: 3;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 20px;
  height: 100%;
  padding: 20px 0;
`;

export const ContactInput = styled.input`
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.colors.footer};
  padding: 16px 16px;
  /* &::placeholder {
    color: ${({ theme }) => theme.colors.text};
  } */
`;

export const ContactTextArea = styled.textarea`
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.colors.footer};
  padding: 16px 16px;
  resize: none;
`;
const ContactUsForm = () => {
  const [requestState, setRequestState] = useState({
    message: "",
    status: "initial",
    error: null,
  });
  const [contactData, setContactData] = useState({
    name: "",
    phone_number: "",
    email: "",
    contact_method: "",
    enquiry: "",
  });

  const handleChange = (e) => {
    setContactData({ ...contactData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setRequestState({ ...requestState, status: "loading" });
    try {
      const response = await fetch({
        url: "http://localhost:8000/contacts/",
        method: "POST",
        body: contactData,
      });
      if (!response.ok) {
        setRequestState({ message: response.text, status: "error" });
      }

      const data = await response.json();
      setRequestState({ message: data.message, status: "success" });
    } catch (error) {
      setRequestState({ requestState, error: error });
    }
  };
  return (
    <ContactForm>
      <h3>Contact form</h3>
      <Form>
        <ContactInput
          name="name"
          id="name"
          type="text"
          placeholder="Name"
          value={contactData.name}
          onChange={handleChange}
        />
        <ContactInput
          name="email"
          id="email"
          type="text"
          placeholder="Email"
          value={contactData.email}
          onChange={handleChange}
        />
        <ContactInput
          name="phone_number"
          id="phone_number"
          type="text"
          placeholder="Phone Number"
          value={contactData.phone_number}
          onChange={handleChange}
        />
        <ContactInput
          name="contect_method"
          id="contect_method"
          type="text"
          placeholder="Contect Method"
          value={contactData.contact_method}
          onChange={handleChange}
        />
        <ContactTextArea
          name="enquiry"
          id="enquiry"
          type="text"
          placeholder="Enquiry"
          rows={6}
          value={contactData.enquiry}
          onChange={handleChange}
        />
        <PrimaryButton
          type="submit"
          onClick={handleSubmit}
          disabled={requestState.status === "loading"}
          status={requestState.status}
        >
          {requestState.status === "loading" ? "loading..." : "Submit"}
        </PrimaryButton>
      </Form>
    </ContactForm>
  );
};

export default ContactUsForm;
