import styled from "styled-components";

export const Button = styled.button`
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  padding: 12px 15px;
  color: white;

  font-size: 16px;
`;

export const PrimaryButton = styled(Button)`
  background-color: ${(prop) =>
    prop.status !== "loading" ? prop.theme.colors.primary : "#adb5bd"};
  /* border: ${({ theme }) => theme.colors.primary} 2px solid; */
`;

export const SecondaryButton = styled(Button)`
  border: white 2px solid;
  background-color: transparent;
`;
