import React from "react";
import styled from "styled-components";
import { Container } from "../components/styles/Container.style";
import { PrimaryButton, SecondaryButton } from "./styles/Buttons.style";
import { Link, useNavigate } from "react-router-dom";
import { Anim } from "../pages/Home";
import { motion } from "framer-motion";

const HeroStyled = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
            url("/static/hero.jpg") no-repeat center ;
background-size: cover;

  background-size: cover;
  /* background-attachment: fixed; */
  width: 100%;
  height: 100vh;

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    gap: 30px;
  }
`;

export const HeroTitle = styled(motion.h1)`
  margin: 0;
  text-transform: uppercase;
  font-family: "Goldman", sans-serif;
  color: #837dff;
  @media (max-width: 620px) {
    font-size: 30px;
  }
`;

const MainHero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 25px;
  flex: 1;
`;


export const HeroText = styled(motion.p)`
  color: white;
`;

export const HeroButtons = styled(motion.div)`
  display: flex;
  gap: 20px;
`;

const Hero = () => {
  const navigate = useNavigate();

  const handleClick = (url) => {
    navigate(url);
  };
  return (
    <HeroStyled>
      <Container className="container">
        <MainHero>
          <HeroTitle
            whileInView={{ x: 0, opacity: 1 }}
            initial={{
              x: -100,
              opacity: 0,
            }}
            transition={{
              duration: 0.5,
              delay: 0.1,
              ease: "easeIn",
            }}
            viewport={{ once: true }}
          >
            Deep Sea Oils{" "}
            <span
              style={{
                textTransform: "capitalize",
                // fontFamily: "Readex Pro, sans-serif",
                // fontSize:'44px'
              }}
            >
              Pty Ltd
            </span>
          </HeroTitle>
          <HeroText
            whileInView={{ x: 0, opacity: 1 }}
            initial={{
              x: -100,
              opacity: 0,
            }}
            transition={{
              duration: 0.5,
              delay: 0.2,
              ease: "easeIn",
            }}
            viewport={{ once: true }}
          >
            Australian Company Number 678 551 034
          </HeroText>
          <HeroButtons
            whileInView={{ x: 0, opacity: 1 }}
            initial={{
              x: -100,
              opacity: 0,
            }}
            transition={{
              duration: 0.5,
              delay: 0.3,
              ease: "easeIn",
            }}
            viewport={{ once: true }}
          >
            <SecondaryButton onClick={() => handleClick("products")}>
              learn more
            </SecondaryButton>
            <PrimaryButton onClick={() => handleClick("contact-us")}>
              contact Us
            </PrimaryButton>
          </HeroButtons>
        </MainHero>
      </Container>
    </HeroStyled>
  );
};

export default Hero;
