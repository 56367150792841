import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";

const SideBarStyled = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: end;
  top: 0;
  height: 100vh;
  z-index: 3;
  right: 0;
  position: absolute;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const SidebarBackground = styled.div`
  height: 100vh;
  z-index: 2;
  top: 0;
  right: 0;
  position: absolute;
  overflow-x: hidden;
  /* background-color: black; */
`;

const SidbarLinks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100px;
  align-items: center;
  margin-top: 50px;
  gap: 10px;
`;

const SidebarLink = styled(Link)`
  text-transform: capitalize;
  text-decoration: none;
  color: white;
`;

const variants = {
  closed: {},
};

const SideBar = ({ open, closeSidebar }) => {
  return (
    <>
      {open && (
        <SidebarBackground
          style={{ width: "100vw" }}
          onClick={closeSidebar}
        ></SidebarBackground>
      )}
      <AnimatePresence>
        {open && (
          <SideBarStyled
            initial={{ width: 0 }}
            animate={{ width: "200px" }}
            transition={{ duration: 0.3 }}
            exit={{ width: 0, transition: { duration: 0.3 } }}
          >
            <div style={{ paddingTop: "26px", paddingRight: "15px" }}>
              <FaBars onClick={closeSidebar} style={{ cursor: "pointer" }} />
            </div>
            <SidbarLinks>
              <SidebarLink to="/" onClick={closeSidebar}>
                Home
              </SidebarLink>
              <SidebarLink to="/products" onClick={closeSidebar}>
                products
              </SidebarLink>
              <SidebarLink to="/contact-us" onClick={closeSidebar}>
                contact us
              </SidebarLink>
            </SidbarLinks>
          </SideBarStyled>
        )}
      </AnimatePresence>
    </>
  );
};

export default SideBar;
