import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export const ProductStyled = styled.div`
  display: flex;
  flex-direction: row-reverse;
  height: 400px;
  gap: 10px;

  /* background-color: #f8f9fa; */

  /* margin: 0 30px; */
  /* box-shadow: 0 0px 15px -3px rgb(0 0 0 / 0.1), 0 0px 10px -4px rgb(0 0 0 / 0.1);
  box-shadow: 0 0px 10px -1px rgb(0 0 0 / 0.1), 0 0px 8px -2px rgb(0 0 0 / 0.1); */
  border-radius: 10px;
  @media (max-width: 820px) {
    flex-direction: column;
    min-height: 600px;
  }
  margin: 20px 0;
`;

const ProductBody = styled.div`
  padding: 10px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 3;
`;

export const ProductDescription = styled(motion.p)`
  line-height: 28px;
  letter-spacing: 0.8px;
  font-size: 18px;
  color: #343a40;
  margin: 0;
  margin-bottom: 20px;
  @media (max-width: 680px) {
    flex-direction: column;
    line-height: 24px;
  }
`;
export const ProductName = styled.h3`
  margin: 0;
  margin-bottom: 10px;
  font-size: 32px;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const ProductImage = styled.div`
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-size: contain;
  /* background-position: center; */
  /* border-radius: 10px; */
  min-height: 300px;
  flex: 2;
`;

const ProductButton = styled(Link)`
  padding: 15px 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  color: white;
  /* border-radius: 10px; */

  font-size: 16px;
`;

const ProductCarouselItem = ({ image, description, name, slug }) => {
  return (
    <ProductStyled>
      <ProductImage
        image={`https://deepseaoils.com.au${image}`}
      ></ProductImage>
      <ProductBody>
        <ProductName>{name}</ProductName>
        <ProductDescription>{description}</ProductDescription>
        <div style={{ marginTop: "auto" }}>
          <ProductButton to={`/products/${slug}`}>detials</ProductButton>
        </div>
      </ProductBody>
    </ProductStyled>
  );
};

export default ProductCarouselItem;
