import React, { useState } from "react";
import styled from "styled-components";
import Product from "./Product";
import { Description } from "../../pages/Home";
import ProductCarouselItem from "./ProductCarouselItem";



//Furthermore, our commitment to quality is evident through our adherence to the Australian Standard in processing, ensuring a pure and contaminant-free product for our customers.

export const ProductListStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 80px;
`;

const ProductList = ({products}) => {

  return (
    <ProductListStyled>
      {products.map((product) => {
        return <ProductCarouselItem {...product} />;
      })}
    </ProductListStyled>
  );
};

export default ProductList;
