import React from "react";
import { SectionStyle } from "../../components/styles/SectionStyle.style";
import SectionTitle from "../../components/SectionTitle";
import { Container } from "../../components/styles/Container.style";
import styled, { useTheme } from "styled-components";
import { FaCheck } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { HiEmojiHappy } from "react-icons/hi";
import { delay, motion } from "framer-motion";

export const FeatureList = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  gap: 40px;
  /* background: ${({ theme }) => theme.colors.primary}; */
  padding: 20px;
  @media (max-width: 820px) {
    flex-direction: column;
  }
`;

export const Feature = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  flex: 1;
  /* box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1); */
  padding: 20px 10px;
  /* border-radius: 10px; */
  background-color: white;
  /* box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px; */
`;

export const FeatureName = styled.p`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
`;

export const FeatureDescription = styled.p`
  text-align: center;
  margin-top: 10px;
  color: #343a40;
`;

export const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
};

const WhyUs = () => {
  const theme = useTheme();

  return (
    <SectionStyle>
      <Container>
        <SectionTitle question="Why Choose Deep Sea Oils?" />
        <FeatureList>
          <Feature
            variants={item}
            whileInView="visible"
            initial="hidden"
            transition={{
               delay: 0.3 ,
              boxShadow: { delay: 1, duration: 0.2 },
            }}
            viewport={{ once: true }}
          >
            <FaCheck color={theme.colors.primary} size={30} />
            <FeatureName>Expertise</FeatureName>
            <FeatureDescription>
              30 years of family experience in the fisheries and marine
              product industry, we ensure the highest standards of quality and
              safety
            </FeatureDescription>
          </Feature>
          <Feature
            variants={item}
            whileInView="visible"
            initial="hidden"
            transition={{
              delay: 0.5 ,
              boxShadow: { delay: 1, duration: 0.2 },
            }}
            viewport={{ once: true }}
          >
            <FaStar color={theme.colors.primary} size={30} />
            <FeatureName>Commitment to Excellence</FeatureName>
            <FeatureDescription>
              From harvest to packaging, our processes are designed to maintain
              the quality and purity of our products
            </FeatureDescription>
          </Feature>
          <Feature
            variants={item}
            whileInView="visible"
            initial="hidden"
            transition={{
               delay: 0.7,
              boxShadow: { delay: 1, duration: 0.2 },
            }}
            viewport={{ once: true }}
          >
            <HiEmojiHappy color={theme.colors.primary} size={30} />
            <FeatureName> Customer Satisfaction</FeatureName>
            <FeatureDescription>
              We are dedicated to providing exceptional customer services and offering competitive market prices
            </FeatureDescription>
          </Feature>
        </FeatureList>
      </Container>
    </SectionStyle>
  );
};

export default WhyUs;
