import { AnimatePresence, wrap, motion } from "framer-motion";
import "./styles.css";

import React, { useState } from "react";
import { IoTriangleSharp } from "react-icons/io5";

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
      // backgroundColor: "white",
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
    // backgroundColor: "#4052b4",
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const CarouselImage = ({ url }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={url} alt="I" style={{maxWidth:'70vw',maxHeight:'50vh'}}/>
    </div>
  );
};

const ImagesCarousel = ({ images }) => {
  const [[page, direction], setPage] = useState([0, 0]);

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, images.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <div style={{ position: "relative" }}>
      <motion.div
        className="image-carousel-container"
        // style={{ height: "60vh" }}
      >
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            src={images[imageIndex].image}
            transition={{
              x: { type: "just", duration: "0.4" },
              opacity: { duration: 0.3 },
            }}
            drag="x"
            style={{ position: "absolute", width: "100%" }}
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          >
            {/* <ProductCarouselItem {...images[imageIndex]} /> */}
            <CarouselImage url={images[imageIndex].image} />
          </motion.div>
        </AnimatePresence>
      </motion.div>
      <div className="next" onClick={() => paginate(-1)}>
        <IoTriangleSharp
          size={25}
          color="#4052b4"
          style={{ rotate: "90deg" }}
        />
      </div>
      <div className="prev" onClick={() => paginate(1)}>
        <IoTriangleSharp
          size={25}
          color="#4052b4"
          style={{ rotate: "-90deg" }}
        />
      </div>
    </div>
  );
};

export default ImagesCarousel;
