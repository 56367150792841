import styled from "styled-components";

const ProductDetailsSpinnerContainer = styled.div`
  height: 70vh;
  width: 95vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;


export default ProductDetailsSpinnerContainer;