import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// import {Helmet} from 'react-helmet'
import { useTheme } from "styled-components";
// import Navbar from "../../layouts/Navbar.jstyle";
// import Footer from "../../layouts/footer/footer.jstyle";
// import { Helmet } from 'react-helmet';


const NotFoundPage = () => {
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Not Found</title>
        
      </Helmet>
      <div style={{ textAlign: "center", padding: "20px" }}>
        <p
          variant="h1"
          style={{
            fontSize: "5rem",
            color: "#ff4a4a",
            marginBottom: "10px",
            marginTop: "60px",
          }}
        >
          404
        </p>
        <p variant="h2" style={{ fontSize: "2rem", marginBottom: "10px" }}>
          page is not availabel
        </p>
        <p variant="body1" style={{ fontSize: "1.2rem", marginBottom: "30px" }}>
          sorry ! page is not found
        </p>
        <Link
          // component={Link}
          to="/"
          style={{
            display: "inline-block",
            padding: "10px 20px",
            backgroundColor: theme.colors.primary,
            color: "#fff",
            textDecoration: "none",
            fontWeight: "bold",
            borderRadius: "30px",
            transition: "background-color 0.3s ease",
            "&:hover": {
              backgroundColor: theme.colors.primary,
              color: "#000",
            },
          }}
        >
          click to go back to home page
        </Link>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default NotFoundPage;
