import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

export const SectionTitleStyled = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 10px;

`;

export const Title = styled.h2`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  @media (max-width: 420px) {
    font-size: 16px;
  }
`;

export const Question = styled.p`
  font-size: 32px;
  color: ${({ theme }) => theme.colors.text};
  text-transform: capitalize;
  font-weight: 600;
  margin: 0;

  @media (max-width: 620px) {
    font-size: 28px;
  }

  @media (max-width: 420px) {
    font-size: 26px;
  }
`;

const SectionTitle = ({ title, question }) => {
  return (
    <SectionTitleStyled
      whileInView={{ x: 0, opacity: 1 }}
      initial={{
        x: -100,
        opacity: 0,
      }}
      transition={{
        duration: 0.5,
        delay: 0.2,
        ease: "easeIn",
      }}
      viewport={{ once: true }}
    >
      <Title>{title}</Title>
      <Question>{question}</Question>
    </SectionTitleStyled>
  );
};

export default SectionTitle;
