import React, { useState } from "react";
import Page, { PageTitle } from "../components/Page";
import styled, { useTheme } from "styled-components";
import { Container } from "../components/styles/Container.style";
// import { Contact, Contacts } from "../components/layout/Footer";
import { MdEmail } from "react-icons/md";
import { FaCheck, FaPhoneAlt, FaStar } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { SectionStyle } from "../components/styles/SectionStyle.style";
import ContactUsForm from "../components/forms/ContactUsForm";
import {
  Feature,
  FeatureDescription,
  FeatureList,
  FeatureName,
  item,
} from "./home/WhyUs";
import { HiEmojiHappy } from "react-icons/hi";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

export const ContactsList = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  gap: 40px;
  /* background: ${({ theme }) => theme.colors.primary}; */
  padding: 20px;
  @media (max-width: 820px) {
    flex-direction: column;
  }
`;

export const ContactCard = styled(motion.a)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  flex: 1;
  /* box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1); */
  padding: 20px 10px;
  /* border-radius: 10px; */
  background-color: white;
  /* box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px; */
`;

export const Contact = styled.p`
  text-align: center;
  margin-top: 10px;
  color: #343a40;
`;

const ContactUs = () => {
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us</title>
        <meta
          name="description"
          content="Get in touch with Deep Sea Oils for premium Shark Liver Oil and seafood supplies. Contact us to learn more about our high-quality products, rooted in over 30 years of maritime tradition and engineered to meet Australian standards AS 5380:2023. We're here to help with any inquiries or support you need."
        />
      </Helmet>
      <PageTitle>contact us</PageTitle>
      <SectionStyle>
        <Container>
          {/* <ContactUsComponent>
            <ContactInfo>
              <h3>Contact info</h3>
              <p>
                If you have any questions or inquiries, we're here to help! Feel
                free to reach out to us through any of the contact methods
                listed, or simply fill out the form on the side. We look forward
                to hearing from you!
              </p>
              <Contacts>
                <Contact fontColor="white" iconColor={theme.colors.secondary}>
                  <MdEmail />
                  <p>info@deepseaoils.com.au</p>
                </Contact>
                <Contact fontColor="white" iconColor={theme.colors.secondary}>
                  <FaPhoneAlt />
                  <p>+61 456 749 375</p>
                </Contact>
                <Contact fontColor="white" iconColor={theme.colors.secondary}>
                  <FaPhoneAlt />
                  <p>+61 411 714 478</p>
                </Contact>
              </Contacts>

              <SocialMedia></SocialMedia>
            </ContactInfo>
            <ContactUsForm />
          </ContactUsComponent> */}

          <ContactsList>
            <ContactCard
              href="mailto:info@deepseaoils.com.au"
              variants={item}
              whileInView="visible"
              initial="hidden"
              transition={{
                delay: 0.3,
                boxShadow: { delay: 1, duration: 0.2 },
              }}
              viewport={{ once: true }}
            >
              <MdEmail color={theme.colors.primary} size={30} />
              <Contact>info@deepseaoils.com.au</Contact>
            </ContactCard>
            <ContactCard
              href={`tel:+61456749375`}
              variants={item}
              whileInView="visible"
              initial="hidden"
              transition={{
                delay: 0.5,
                boxShadow: { delay: 1, duration: 0.2 },
              }}
              viewport={{ once: true }}
            >
              <FaPhoneAlt color={theme.colors.primary} size={30} />
              <Contact>+61 456 749 375</Contact>
            </ContactCard>
            <ContactCard
              href={`tel:+61411714478`}
              variants={item}
              whileInView="visible"
              initial="hidden"
              transition={{
                delay: 0.7,
                boxShadow: { delay: 1, duration: 0.2 },
              }}
              viewport={{ once: true }}
            >
              <FaPhoneAlt color={theme.colors.primary} size={30} />
              <Contact>+61 411 714 478</Contact>
            </ContactCard>
          </ContactsList>
        </Container>
      </SectionStyle>
    </>
  );
};

export default ContactUs;
