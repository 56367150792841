import React, { useCallback, useEffect, useState } from "react";
import { Container } from "../../components/styles/Container.style";
import { PageTitle } from "../../components/Page";
import ImagesCarousel from "../../components/ImagesCarousel";
import dompurify from "dompurify";
import { PuffLoader } from "react-spinners";
import ProductDetailsSpinnerContainer from "../../components/styles/ProductDetailsSpinnerContainer";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const ProductDetails = () => {
  const { productSlug } = useParams();
  const [requestState, setRequestState] = useState({
    isLoading: true,
    error: null,
  });
  const [product, setProduct] = useState({
    id: "",
    name: "",
    sanitizedOverview: "",
    images: "",
    description:'',
  });

  const fetchProduct = useCallback(async () => {
    // setRequestState((requestState) => ({ ...requestState, isLoading: true }));
    try {
      const response = await fetch(`/api/products/${productSlug}/`);

      if (!response.ok) {
        throw Error(response.text);
      }

      const data = await response.json();

      setProduct({
        ...data,
        sanitizedOverview: dompurify.sanitize(data.overview),
      });
    } catch (e) {
      setRequestState((requestState) => ({ ...requestState, error: e }));
    } finally {
      setRequestState((requestState) => ({
        ...requestState,
        isLoading: false,
      }));
    }
  }, [productSlug]);

  //   const sanitizedOverview = dompurify.sanitize(overview);

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  return (
    <>
      {requestState.isLoading ? (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{product.name}</title>
          <meta
            name="description"
            content={product.description}
          />
        </Helmet>
      ) : (
        <></>
      )}

      <Container style={{ overflow: "hidden", marginBottom: "50px" }}>
        {requestState.isLoading ? (
          <ProductDetailsSpinnerContainer>
            <PuffLoader />
          </ProductDetailsSpinnerContainer>
        ) : (
          <>
            <PageTitle>{product.name}</PageTitle>
            <div style={{ padding: "0 20px" }}>
              <ImagesCarousel images={product.images} />
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: product.sanitizedOverview }}
            ></div>
          </>
        )}
      </Container>
    </>
  );
};

export default ProductDetails;
