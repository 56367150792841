import React, { useCallback, useEffect, useState } from "react";
import { SectionStyle } from "../../components/styles/SectionStyle.style";
import { Container } from "../../components/styles/Container.style";
import SectionTitle from "../../components/SectionTitle";
import ProductsCarousel from "../../components/products/ProductsCarousel";
import styled, { useTheme } from "styled-components";
import { Link } from "react-router-dom";
import { PuffLoader } from "react-spinners";

export const Anim = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 50px;
  width: 150px;
  border: ${({ theme }) => theme.colors.text} 1.5px solid;
  overflow-y: hidden;

  .animated {
    width: 100%;

    left: 0px;
    right: 0px;
    position: absolute;
    bottom: 0px;
    background-color: ${({ theme }) => theme.colors.primary};
    transition: 0.5s;
    top: 50px;
  }

  &:hover .animated {
    transition: 3s;
    top: -200%;
    animation: animate 1.5s infinite;
  }

  @keyframes animate {
    0%,
    100% {
      clip-path: polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
      );
    }

    50% {
      clip-path: polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
      );
    }
  }
`;

const Products = () => {
  const theme = useTheme();
  const [requestState, setRequestState] = useState({
    isLoading: true,
    error: null,
  });
  const [products, setProducts] = useState([]);

  const fetchProduct = useCallback(async () => {
    // setRequestState((requestState) => ({
    //   ...requestState,
    //   isLoading: true,
    // }));
    try {
      const response = await fetch('/api/products/');

      if (!response.ok) {
        throw Error(response.text);
      }

      const data = await response.json();
     
      setProducts(data);
    } catch (e) {
      setRequestState((requestState) => ({ ...requestState, error: e }));
    } finally {
      setRequestState((requestState) => ({
        ...requestState,
        isLoading: false,
      }));
    }
  }, []);

  //   const sanitizedOverview = dompurify.sanitize(overview);

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  return (
    <SectionStyle>
      <Container style={{ overflow: "hidden" }}>
        <SectionTitle title="products" question="what we offer" />
        <div style={{ padding: "0 20px" }}>
          {requestState.isLoading ? (
            <div style={{justifyContent:'center' ,alignContent:'center',height:'200px',display:'flex',marginTop:'30px'}}>
              <PuffLoader size={100} color={theme.colors.primary}/>
            </div>
          ) : (
            <ProductsCarousel products={products} />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Anim>
            <Link
              to="/products"
              style={{
                zIndex: 10,
                color: theme.colors.text,
                textDecoration: "none",
                fontSize: "14px",
                fontWeight: "bold",
                // height:'100%',
              }}
            >
              ALL PRODUCTS
            </Link>
            <div className="animated"></div>
          </Anim>
        </div>
      </Container>
    </SectionStyle>
  );
};

export default Products;
