import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "../styles/Container.style";
import { Logo } from "../styles/NavLinks.style";
import { Link } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";

import { FaWhatsapp } from "react-icons/fa";

export const FooterStyled = styled.footer`
  background-color: ${({ theme }) => theme.colors.footer};
  padding: 30px 0 40px 0;

  p {
    font-size: 16px !important;
  }
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    /* padding: 20px 0; */
    flex-wrap: wrap;

    & > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 150px;
    }
  }

  @media (max-width: 720px) {
    .container {
      flex-direction: column;
      /* align-items: start; */
      gap: 30px;
    }
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const Contact = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: ${({ fontColor }) => fontColor};
  svg {
    color: ${({ iconColor }) => iconColor};
  }
`;

export const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
`;

export const SubscriptionForm = styled.div`
  display: flex;
  align-items: center;
  width: 350px;
  margin-top: 5px;

  @media (max-width: 620px) {
    width: 250px;
  }

  @media (max-width: 820px) {
    width: 300px;
  }

  input {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    color: white;
    height: 40px;
  }
  input,
  input:focus {
    outline: none;
    border: none;
    background-color: ${({ theme }) => theme.colors.primary};
    width: 100%;
  }
  input::placeholder {
    color: white;
  }

  div:nth-child(1) {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: ${({ theme }) => theme.colors.primary};
    padding-left: 20px;
    height: 40px;
    flex: 1;
  }
  div:nth-child(2) {
    height: 40px;
    width: 60px;
  }

  button {
    cursor: pointer;
    height: 100%;
    /* background-color: ${({ theme }) => theme.colors.text}; */
    background-color: ${(prop) =>
      prop.status !== "loading" ? prop.theme.colors.text : "#adb5bd"};
    color: white;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border: none;
    width: 70px;
    font-size: 14px;
  }
`;

export const DeveloperInfo = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    text-align: center;
    font-size: 14px;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Footer = () => {
  const [email, setEmail] = useState();
   const [requestState, setRequestState] = useState({
     message: "",
     status: "initial",
     error: null,
   });

  const handleSend = async (e) => {
       e.preventDefault();
       setRequestState({ ...requestState, status: "loading" });
    try {
      const response = await fetch({
        url: "http://localhost:8000/newletter-subscriptions/",
        method: "POST",
        body: { email },
      });

      if (!response.ok) {
        setRequestState({ message: response.text, status: "error" });
      }

      const data = await response.json();
      setRequestState({ message: data.message, status: "success" });
    } catch (error) {
      setRequestState({ requestState, error: error });
    }
  };

  return (
    <div>
      <FooterStyled>
        <Container className="container">
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyItems: "center",
              height: "100%",
            }}
          >
            <Logo src="/static/logo.webp" style={{ width: "150px" }} />
            {/* <div>
              <p>Subscribe to receive news and updates:</p>
              <SubscriptionForm status={requestState.status}>
                <div>
                  <input
                    type="text"
                    placeholder="your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <button onClick={handleSend}>
                    {requestState.status === "loading" ? "sending..." : "send"}
                  </button>
                </div>
              </SubscriptionForm>
            </div> */}
            {/* <p>Premium Frozen Fish & Fish Oils Wholesale</p> */}
          </div>
          <FooterLinks>
            <FooterLink to="/">Home</FooterLink>
            <FooterLink to="/products">Products</FooterLink>
            <FooterLink to="/contact-us">Contact Us</FooterLink>
          </FooterLinks>
          <div>
            <Contacts>
              <Contact>
                <MdEmail />
                <p>info@deepseaoils.com.au</p>
              </Contact>
              <Contact>
                <FaPhoneAlt />
                <p>+61 456 749 375</p>
              </Contact>
              <Contact>
                <FaPhoneAlt />
                <p>+61 411 714 478</p>
              </Contact>
              {/* <Contact>
              <FaWhatsapp />
              <p>whatsupp</p>
            </Contact> */}
            </Contacts>
            <div style={{ marginTop: "15px" }}>
              <p style={{ marginBottom: "5px" }}>social media</p>
              <Link
                to="https://api.whatsapp.com/send?phone=61456749375"
                target="_blanck"
              >
                <FaWhatsapp size={25} />
              </Link>
            </div>
          </div>
        </Container>
      </FooterStyled>
      <DeveloperInfo>
        <p>
          Designed and developed by{" "}
          <a
            href="https://api.whatsapp.com/send?phone=79313556578"
            target="_blanck"
          >
            DevTech
          </a>
        </p>
      </DeveloperInfo>
    </div>
  );
};

export default Footer;
