const Theme = {
  colors: {
    primary: "#4052B4",
    secondary: "#010101",
    text: "#212529",
    footer: "#f8f9fa",
  },
  fonts: {
    readuxPro: "Readex Pro, sans-serif",
  },
};

export default Theme;
