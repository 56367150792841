import React, { useCallback, useEffect, useState } from "react";
import Page, { PageTitle } from "../components/Page";
import { SectionStyle } from "../components/styles/SectionStyle.style";
import { Container } from "../components/styles/Container.style";
import ProductList from "../components/products/ProductList";
import { motion } from "framer-motion";
import { PuffLoader } from "react-spinners";
import { useTheme } from "styled-components";
import { Helmet } from "react-helmet";

const Products = () => {
  const theme = useTheme();
  const [requestState, setRequestState] = useState({
    isLoading: false,
    error: null,
  });
  const [products, setProducts] = useState([]);

  const fetchProduct = useCallback(async () => {
    setRequestState((requestState) => ({
      ...requestState,
      isLoading: true,
    }));
    try {
      const response = await fetch("/api/products/");

      if (!response.ok) {
        throw Error(response);
      }

      const data = await response.json();
      setProducts(data);
    } catch (e) {
      setRequestState((requestState) => ({ ...requestState, error: e }));
    } finally {
      setRequestState((requestState) => ({
        ...requestState,
        isLoading: false,
      }));
    }
  }, []);

  //   const sanitizedOverview = dompurify.sanitize(overview);

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Products</title>
        <meta
          name="description"
          content="Discover premium Shark Liver Oil and seafood supplies at Deep Sea Oils. Founded by two engineers with over 30 years of family heritage in the fishing industry, we ensure optimal quality and purity, meeting Australian standards AS 5380:2023. Experience the perfect blend of scientific precision and traditional expertise in every product"
        />
      </Helmet>
      <PageTitle>our products</PageTitle>
      <SectionStyle>
        <Container>
          {requestState.isLoading ? (
            <div
              style={{
                justifyContent: "center",
                alignContent: "center",
                height: "200px",
                display: "flex",
                marginTop: "30px",
              }}
            >
              <PuffLoader size={100} color={theme.colors.primary} />
            </div>
          ) : (
            <ProductList products={products} />
          )}
        </Container>
      </SectionStyle>
    </>
  );
};

export default Products;
