import React from "react";
import Navbar from "./layout/Navbar";
import Footer from "./layout/Footer";
import styled from "styled-components";
import SideBar from "./layout/SideBar";

export const PageTitle = styled.h1`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  margin-top: 20px;

  @media (max-width: 420px) {
    font-size: 32px;
  }
`;

const Page = ({ children }) => {
  return (
    <>
      <Navbar />

      {children}
      <Footer />
    </>
  );
};

export default Page;
